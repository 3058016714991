import {
    $, addAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const dataAttr = 'hv-link';
    const queryParam = 'IRCfamily';
    const highValueKey = '1';
    const $donateButton = $('.donate-button');
    if (!$donateButton.length) {
        return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const donorValue = urlParams.get(queryParam);
    $donateButton.each((i, e) => {
        const $this = $(e);
        const highValue = $this.data(dataAttr);
        if (highValue && donorValue === highValueKey) {
            $this.attr('href', highValue);
        }
        const $icon = $this.find('.donate-button__icon');
        const $label = $this.find('.donate-button__label');
        const iconWidth = $icon.innerWidth();
        const labelWidth = $label.innerWidth();
        $label.css({
            width: labelWidth,
            marginRight: 0,
        });
        $this.on('mouseover', () => {
            $label.css({
                width: (iconWidth + labelWidth),
                marginRight: (iconWidth * -1),
            });
        });
        $this.on('mouseleave', () => {
            $label.css({
                width: labelWidth,
                marginRight: 0,
            });
        });
    });
});
